/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"zamek"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center pb--60 pt--60" name={"uvod"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--62 w--900" style={{"maxWidth":700}} content={"Zadlabací zámek"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l13"} name={"warewaf3o0q"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Text className="text-box" content={"Nejdůležitější jsou celkem čtyři parametry.\n<br>1. ROZTEČ\n<br>2. DORN\n<br>3. ZÁDLAB\n<br>4. ORIENTACE\n<br><br>&nbsp;Rozteče jsou tři základní 72, 90 a 92 a měří ze od osy cylindrické vložky k ose ořechu kliky. Rozteč 92 je typická pro profilové dveře, jako jsou například hliníkové, nebo plastové vstupní dveře. DORN je vzdálenost od čela zámku k ose cylindrické vložky, nebo k ose ořechu kliky. Zádlab je celková hloubka zámku. Vše je názorně zobrazeno na obrázku. Dalším parametrem je orientace dveří. Zámky můžou mít možnost otočit orientaci, ale některé jsou pouze pro pravé, nebo levé dveře a nelze je zaměnit. Zámky můžeme mít na obyčejný tvarový klíč, dozický klíč a nebo na cylindrickou vložku."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19567/b0b21c8451df47d79f3acebef42a6deb_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} RootClassName={"column__pic"} srcSet={"https://cdn.swbpg.com/t/19567/b0b21c8451df47d79f3acebef42a6deb_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/b0b21c8451df47d79f3acebef42a6deb_s=660x_.png 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-14bc1xq pb--20 pt--80" layout={"l1"} name={"o7npbvpt02o"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --right el--2 flex--bottom" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: var(--white);\">Váš KLÍČNÍK</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: var(--white);\">Miroslav Košůtek\n<br>Náplatková 216/12, 747 23, Bolatice\n<br>IČ: 86670565\n<br>DIČ: CZ7302125435</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}